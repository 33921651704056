@import "variables";

.layout-main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding: 6.5rem 6rem 2rem 6rem;
  transition: margin-left $transitionDuration;
}

.remove-layout-padding {
  margin-left: -6rem !important;
  margin-right: -6rem !important;
}
