$primary050: #fdfefe !default;
$primary100: #ebedf8 !default;
$primary200: #d7dcf3 !default;
$primary300: #c2caf0 !default;
$primary400: #abb8ed !default;
$primary500: #93a4ec !default;
$primary600: #607bee !default;
$primary700: #274ff5 !default;
$primary800: #1638a8 !default;
$primary900: #0a1e57 !default;
$primary950: #000103 !default;

$primaryColor: $primary700 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #F5F3FF !default;
$highlightTextColor: $primary700 !default;
$highlightFocusBg: rgba($primaryColor, .24) !default;

@import 'variables';
@import 'fonts';
@import '../theme-base/components';
@import 'extensions';
